<template>
  <div class="home_page">
    <router-link to="/signin">
      <div class="img_box">
        <img class="home_logo" :src="homeLogo" alt="" />

        <el-button class="home_btn" type="primary">
          手机号登录/注册
        </el-button>
      </div>
    </router-link>
  </div>
</template>

<script>
import homeLogo from "@/assets/imgs/home_logo.png";
export default {
  data() {
    return {
      homeLogo: homeLogo
    };
  }
};
</script>

<style lang="scss" scoped>
.home_page {
  
  background: #910000;
  height: 100vh;
  position: relative;
  overflow: hidden;
  a {
    text-align: center;
  }
  .img_box {
    margin-top: 2rem;
  }
  .home_logo {
    width: 3.4rem;
  }
  .home_btn {
    border-radius: 100px;
    margin-top: 1rem;
    width: 5rem;
    color: #910000;
    background: #fff;
  }
}
</style>
